<template>
    <v-container fluid class="px-6">

        <v-card elevation="2">

            <v-card-title class="px-6 card-header white--text">
                <v-icon class="mr-3 white--text">mdi-plus</v-icon> <h3>Add Point Of Interest</h3>
            </v-card-title>

            <v-container fluid class="pa-6">
                <v-tabs
                v-model="tabs"
                background-color="primary"
                >
                    <v-tab v-for="item in tabsInterest" :key="item.tab" class="white--text">
                        {{ item.tabName }}
                    </v-tab>
                </v-tabs>

                <v-tabs-items v-model="tabs">
                    <v-tab-item>
                        <v-container class="px-6">
                            <v-form ref="pointForm">
                                <v-row>
                                    <v-col cols="12" md="6" class="pb-0">
                                        <v-subheader class="pa-1 black--text">Point Of Interest Name :</v-subheader>
                                        <v-text-field
                                            v-model="pointData.name"
                                            :rules="[() => !!pointData.name || 'This field is required']"
                                            required
                                            outlined
                                            dense
                                            autocomplete="off"
                                        ></v-text-field>
                                    </v-col>

                                    <v-col cols="12" md="6" class="pb-0">
                                        <v-subheader class="pa-1 black--text">Description :</v-subheader>
                                        <v-text-field
                                            v-model="pointData.description"
                                            :rules="[() => !!pointData.description || 'This field is required']"
                                            required
                                            outlined
                                            dense 
                                            autocomplete="off"
                                        ></v-text-field>
                                    </v-col>

                                    <v-col cols="12" md="6" class="pb-0 pt-0">
                                        <v-subheader class="pa-1 black--text">Longitude :</v-subheader>
                                        <v-text-field
                                            v-model="pointData.longitude"
                                            append-icon="mdi-longitude"
                                            :rules="[() => !!pointData.longitude || 'This field is required']"
                                            required
                                            outlined
                                            dense 
                                            autocomplete="off"
                                        ></v-text-field>
                                    </v-col>

                                    <v-col  cols="12"  md="6" class="pb-0 pt-0" >
                                        <v-subheader class="pa-1 black--text">Latitude :</v-subheader>
                                        <v-text-field
                                            v-model="pointData.latitude"
                                            append-icon="mdi-latitude"
                                            :rules="[() => !!pointData.latitude || 'This field is required']"
                                            required
                                            outlined
                                            dense 
                                            autocomplete="off"
                                        ></v-text-field>
                                    </v-col>

                                    <v-col cols="12" md="6" class="pb-0 pt-0">
                                        <v-subheader class="pa-1 black--text">Point of Interest Picture :</v-subheader> 
                                        <v-file-input
                                            @change="uploadPictureInterest(uploadPic)"
                                            v-model="uploadPic"
                                            dense
                                            hide-details
                                            outlined
                                        ></v-file-input>
                                    </v-col>

                                </v-row>

                                <v-divider class="my-10"></v-divider>
                                
                                <v-row justify="center">
                                    <v-btn class="mr-4 button white--text" @click="postPointData" :loading="loadingButton"> <v-icon small class="mr-2">mdi-plus</v-icon>ADD</v-btn>
                                </v-row>
                            </v-form>
                        </v-container>
                    </v-tab-item>

                    <v-tab-item>
                        <v-container class="px-6">
                            <v-row>
                                <v-col cols="12" md="6" class="pb-0 pt-0">
                                    <v-subheader class="pa-1 black--text">Point of Interest Files :</v-subheader> 
                                    <v-file-input
                                        accept=".doc,.pdf,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                        @change="uploadFileInterest(uploadFile)"
                                        v-model="uploadFile"
                                        dense
                                        hide-details
                                        outlined
                                    ></v-file-input>
                                </v-col>
                            </v-row>

                            <v-divider class="my-10"></v-divider>
                                
                            <v-row justify="center">
                                <v-btn class="mr-4 button white--text" @click="postPointData" :loading="loadingButton"> <v-icon small class="mr-2">mdi-plus</v-icon>ADD</v-btn>
                            </v-row>

                        </v-container>
                    </v-tab-item>
                </v-tabs-items>
            </v-container>
            
        </v-card>

        <v-dialog v-model="successDialog" persistent max-width="500px">
            <v-card>

                <v-card-title class="text-h5 white--text button">
                    <v-icon class="white--text mr-2">mdi-text-box-check</v-icon>Form has been submitted
                </v-card-title>

                <v-card-text class="pa-5 d-flex justify-center">
                    <h3>Point of Interest successfully added</h3> 
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                    text
                    @click="successDialog = false"
                    >
                    Close
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!----- Snackbar Message ---->
        <v-snackbar
            v-model="snackbarMsg"
            :color="snackbarColor"
        >
            {{ message }}

            <template v-slot:action="{ attrs }">
            <v-btn
                text
                v-bind="attrs"
                @click="snackbarMsg = false"
            >
                <v-icon>mdi-close-circle-outline</v-icon>
            </v-btn>
            </template>
        </v-snackbar>

    </v-container>
</template>

<script>
import axios from 'axios'

export default {
  data: () => ({
    tabs: null,
    tabsInterest: [
        {tabName: "Form"},
        {tabName: "Upload Files"},
    ],
    snackbarMsg: false,
    snackbarColor: '',
    message: '',
    successMsg: '',
    successDialog: false,
    uploadPic: null,
    uploadFile: null,
    pointData: {
        name: '',
        description: '',
        longitude: '',
        latitude: '',
        picturePath: ''
    },
    loadingButton:false

  }),

  methods: {

      

    postPointData() {

        this.loadingButton= true;

        const payload = {
            name: this.pointData.name,
            description: this.pointData.description,
            longitude: this.pointData.longitude,
            latitude: this.pointData.latitude,
            picturePath: this.pointData.picturePath,
        }

        axios.post(this.globalUrl+'bakaj/addinterest', payload,{
            headers: {
                Authorization: 'Bearer ' + this.$store.getters.user.token,
            },
        })
        .then((response) => {
            this.loadingButton= false;
            this.successDialog = true
            this.$refs.pointForm.reset();
        })
        .catch((error) => {
            this.loadingButton= false;
            this.snackbarMsg = true;
            this.snackbarColor = "error";
            this.message = "Error. Please try again";
            console.log(error)
        })
    },

    close () {
        this.successDialog = false
    },

    uploadPictureInterest(data){

        let formData = new FormData();

        formData.append("files", data);

        axios.post(this.globalUrl+'bakaj/upload', formData, {
            headers: {
                Authorization: "Bearer " + this.$store.getters.user.token,
            },
        })
        .then((response) => {
            // this.stationData = response.data.filepath
            this.pointData.picturePath = response.data.filepath;
        })
        .catch((error) => {
            console.log(error); 
        });

    },

    uploadFileInterest(file){
        console.log(file);
    }

  }
}
</script>

<style lang="scss" scoped>


</style>